






















import {Component} from "vue-property-decorator";
import BaseField from "@/components/Shared/Table/BaseField.vue";
import SiteValueId from "@/models/SiteValueId";
import GroupedType5CoefficientSelectedValueByValue from "@/models/GroupedType5CoefficientSelectedValueByValue";

@Component({
  components: {},
})
export default class CoefficientSitesField extends BaseField {
  private vue: any = this.$parent.$parent.$parent;

  private get currentRow(): GroupedType5CoefficientSelectedValueByValue {
    return this.props.rowData;
  }

  private get sites(): Array<SiteValueId> {
    return this.currentRow.siteValueIdList;
  }

  /*
  See openModal() in CoefficientPerSitesSetup.vue
   */
  public openCoefficientSitesTableModal(event: Event, props: any): void {
    if (this.canBeEdited) this.vue.openModal(props);
  }

}
